select {
  height: 48px;
  margin: auto;
  display: block;
  width: 350px;
  font-size: 16pt;
}
input {
  height: 36px;
  font-size: 28pt;
  width: 128px;
}
